import {
    Grid,
    GridColumn,
    GridColumnProps,
    GridNoRecords,
    GridRowProps,
} from "@progress/kendo-react-grid";
import cx from "classnames";
import * as React from "react";
import {
    ColumnMenuFilter,
    commonFilterOperators,
} from "../../../../../common/components/table/kendo/columnFilters";
import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import "./examinationGrid.scss";
import ExaminationGridActions from "./ExaminationGridActions";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";
import { BaseExamination } from "../../domain/examinationsReview";
import { ExamGridType } from "../../query/examinationsContextProvider";

interface Props<T> {
    data: T[];
    columnsDefinitions: GridFieldsMap;
    isLoading: boolean;
    processId: string;
    activityType: ExamGridType;
    onDelete: (activityItemId: string) => void;
}

function ExaminationGrid<TData extends BaseExamination>({
    data,
    columnsDefinitions,
    isLoading,
    processId,
    activityType,
    onDelete,
}: Readonly<Props<TData>>): ReturnType<React.FC<Props<TData>>> {
    const { navigateToWithOrg } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const closed = rowProps.dataItem.status === "Closed";

        const trProps = {
            ...row.props,
            className: closed
                ? cx("closed", row.props.className)
                : row.props.className,
        };

        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="examinations-defects-grid-h pt-2"
        >
            {isLoading && <GridLoadingPanel gridRef={wrapperRef} />}
            <Grid
                data={data}
                reorderable={true}
                resizable={true}
                fixedScroll={true}
                style={{ maxHeight: "500px" }}
                sortable={{
                    allowUnsort: true,
                    mode: "multiple",
                }}
                filterOperators={commonFilterOperators}
                onRowDoubleClick={({ dataItem }) =>
                    navigateToWithOrg(AppRoutes.ProcessActivity, {
                        processId,
                        activityId: dataItem.activityId,
                    })
                }
                className="examinations-grid-h"
                rowRender={rowRender}
            >
                <GridNoRecords>No data</GridNoRecords>
                {Object.values(columnsDefinitions).map(
                    ({ field, label, dataType, width }, index) => {
                        const key = `${field}-${index}`;
                        const props: GridColumnProps = {
                            field: field,
                            title: label || field,
                            width: width,
                        };
                        if (dataType === "Date") {
                            props.filter = "date";
                            props.format = "{0:dd/MM/yyyy}";
                        }

                        return (
                            <GridColumn
                                columnMenu={ColumnMenuFilter}
                                key={key}
                                {...props}
                            />
                        );
                    },
                )}
                <GridColumn
                    key="actions"
                    field="actions"
                    title="Actions"
                    width={150}
                    filterable={false}
                    locked={true}
                    cell={(props: TypedGridCellProps<TData>) => {
                        return ExaminationGridActions<TData>({
                            props: props,
                            activityType: activityType,
                            onDelete: onDelete,
                        });
                    }}
                />
            </Grid>
        </div>
    );
}

export default ExaminationGrid;
