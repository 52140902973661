import {
    Configuration,
    RedirectRequest,
    PublicClientApplication,
    InteractionRequiredAuthError,
    ClientAuthError,
    EventMessage,
    EventType,
    AuthenticationResult,
    AccountInfo,
} from "@azure/msal-browser";
import { BaseUrl } from "../../../Constants/ApiAuthorizationConstants";
import { AzureB2CModel } from "../../../common/types/AzureB2CModel";
import Identity from "../models/Identity";

interface B2cPolicies {
    signUpSignInPolicy: { name: string; url: string };
    editProfilePolicy: { name: string; url: string };
}
class AuthService {
    msalClient: PublicClientApplication;
    private msalConfig: Configuration;
    private signInOptions: RedirectRequest;
    private b2cPolicies: B2cPolicies;

    initialize(
        config: AzureB2CModel,
        setLoggedAccount: (account: AccountInfo) => void,
    ): AccountInfo {
        this.signInOptions = {
            scopes: ["openid", "profile", config.ClientId],
        };

        this.b2cPolicies = {
            signUpSignInPolicy: {
                name: config.SignUpSignInPolicyId,
                url: `${config.Instance}/${config.Domain}/${config.SignUpSignInPolicyId}`,
            },
            editProfilePolicy: {
                name: config.EditProfilePolicyId,
                url: `${config.Instance}/${config.Domain}/${config.EditProfilePolicyId}`,
            },
        };

        this.msalConfig = {
            auth: {
                clientId: config.ClientId,
                authority: this.b2cPolicies.signUpSignInPolicy.url,
                redirectUri: BaseUrl,
                knownAuthorities: [config.Instance],
                postLogoutRedirectUri: BaseUrl,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
        };

        this.msalClient = new PublicClientApplication(this.msalConfig);
        this.msalClient.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                this.msalClient.setActiveAccount(account);
                setLoggedAccount(account);
            }

            if (
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
                event.payload
            ) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                if (account) {
                    setLoggedAccount(account);
                    this.msalClient.setActiveAccount(account);
                }
            }
        });

        const accounts = this.msalClient.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts[0];
            this.msalClient.setActiveAccount(account);
            return account;
        }
    }

    async signIn(): Promise<void> {
        await this.msalClient.loginRedirect(this.signInOptions);
    }

    async signOut(): Promise<void> {
        await this.msalClient.logoutRedirect();
    }

    async editProfile(): Promise<void> {
        const editProfileRequest = {
            ...this.signInOptions,
            authority: this.b2cPolicies.editProfilePolicy.url,
            loginHint: this.msalClient.getActiveAccount().username,
            redirectStartPage: BaseUrl + "profile-updated",
        };
        this.msalClient.loginRedirect(editProfileRequest);
    }

    async getAccessToken() {
        const identity = await this.getIdentity();
        if (!identity) {
            return "";
        }
        return identity.accessToken;
    }
    async getIdentity() {
        const account = this.msalClient.getActiveAccount();
        if (!account) {
            return;
        }

        try {
            const response = await this.msalClient.acquireTokenSilent(
                this.signInOptions,
            );
            return new Identity(response);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                console.error("getIdentity", error);
            }
            if (error instanceof ClientAuthError) {
                if (error.errorCode === "block_token_requests") {
                    console.error("getIdentity", error);
                }
                console.warn("ClientAuthError: error code = ", error.errorCode);
            }
            throw error;
        }
    }
}

const authService = new AuthService();
export default authService;
