import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useGetUsersByOrgIdQuery } from "../../../../../store/features/organisation/organisation-api-slice";

interface Props extends GridFilterCellProps {
    value: string[];
    organisationId: string;
}

export const MultiTextContainsFilterCell: React.FC<Props> = (props) => {
    return <ExaminerAndEngineerMultiSelectFilterCell {...props} />;
};

const doesNotContainOption = "does not contain";

export const doesNotContainIsIn = (current: string, value: string[]) => {
    if (value.length === 0) return value;

    return (
        value?.includes(current) ||
        (value?.includes(doesNotContainOption)
            ? current === null || current === ""
            : false)
    );
};

export const ExaminerAndEngineerMultiSelectFilterCell: React.FC<Props> = ({
    onChange,
    value,
    organisationId,
}) => {
    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const organisationUser = [
        doesNotContainOption,
        ...(organisationUsers?.map((x) => x.username) ?? []),
    ];

    const onDropdownChange = React.useCallback(
        ({ value }: MultiSelectChangeEvent) => {
            onChange({
                value: value,
                operator: doesNotContainIsIn,
                syntheticEvent: null,
            });
        },
        [],
    );

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <MultiSelect
                    data={Array.from(organisationUser)}
                    onChange={onDropdownChange}
                    value={value}
                    tags={
                        value.length > 0
                            ? [
                                  {
                                      text: `${value.length} selected user`,
                                      data: organisationUser,
                                  },
                              ]
                            : null
                    }
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
