import { Dialog } from "@progress/kendo-react-dialogs";
import * as React from "react";
import ProcessActivityFormWrapper from "../../../../process-activity-form/components/ProcessActivityFormWrapper";
import { useGetOrgByIdQuery } from "../../../../../store/features/organisation/organisation-api-slice";
import { useGetProcessActivityFormByIdQuery } from "../../../../process-activity-form/store/processActivityFormApiSlice";

interface ExaminationActivityModalProps {
    activityId: string;
    processId: string;
    organisationId: string;
    onClose: () => void;
}

const ExaminationActivityModal: React.FC<ExaminationActivityModalProps> = ({
    activityId,
    processId,
    organisationId,
    onClose,
}) => {
    const { data: organisation } = useGetOrgByIdQuery(organisationId);

    const { data: activityForm } = useGetProcessActivityFormByIdQuery(
        { activityId, processId },
        {
            skip: !activityId,
        },
    );

    return (
        <Dialog
            title={activityForm?.displayName ?? "..."}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
        >
            <ProcessActivityFormWrapper
                processId={processId}
                activityId={activityId}
                organisation={organisation}
                disableTopBar={true}
            />
        </Dialog>
    );
};

export default ExaminationActivityModal;
