import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useGetExamContextBar } from "../query/examContextBarQueries";
import { ExaminationReview } from "../domain/examinationsReview";
import { ProcessActivityGroup } from "../../../process-activity-group/domain/types";
import ProcessActivityAlertButton from "../../../process-activity/components/ProcessActivityAlertButton";
import { ProcessActivityAlert } from "../../../process-activity/domain/types";

interface Props {
    organisationId: string;
    review: ExaminationReview;
    reviewPaGroup: ProcessActivityGroup;
}

export const CefaEngineerContextBar: React.FC<Props> = ({
    organisationId,
    review,
    reviewPaGroup,
}) => {
    const { data, isFetching } = useGetExamContextBar(
        organisationId,
        review.processId,
    );

    if (isFetching || !data) return null;

    const generateAlert = (alert: ProcessActivityAlert) => {
        return alert.buttons.map((alertButton) => (
            <ProcessActivityAlertButton
                key={alertButton.type}
                alertButton={alertButton}
            />
        ));
    };

    const alerts = reviewPaGroup.alerts.map(generateAlert);

    const isAssigned = review.assignedEngineer ?? false;

    return (
        <>
            <Row style={{ position: "sticky", zIndex: 1000, top: 57 }}>
                <Col style={{ paddingLeft: 0 }}>
                    <Alert variant={"primary"} className="rounded-0 sticky-top">
                        <div
                            className="pt-2"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <b>{data}</b>
                                {isAssigned ? (
                                    <div>{`This review is assigned to ${review.assignedEngineer}`}</div>
                                ) : (
                                    <div>{`No Engineer has been assigned to this review`}</div>
                                )}
                            </div>
                            {reviewPaGroup && <>{alerts}</>}
                        </div>
                    </Alert>
                </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}></Row>
        </>
    );
};
